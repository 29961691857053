import { CloseButton, Modal } from "react-bootstrap";
import { ModalPropsDefault, MutationData, Period, Programmation } from "../../types";
import { useAuth } from "../../contexts/auth";
import { getOperators, ModalTypeEnum, SchoolNivelEnum, TextProblemTypeEnum } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCheck, faFont, faInfoCircle, faLessThanEqual, faPen, faRoute } from "@fortawesome/free-solid-svg-icons";
import { useModals } from "../../contexts/modals";
import { LinearProgress } from "@mui/material";
import FormButtonLoading from "../FormButtonLoading";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { api, ApiError } from "../../hooks";

function ProgrammationModal({ show, closeModal }: Readonly<ModalPropsDefault>) {
  const { user, setProgrammation } = useAuth();
  const { openModal } = useModals();

  const periods: Period[] = [1, 2, 3, 4, 5, 1.5, 2.5, 3.5, 4.5, 5.5];
  const canModify = user?.isAdherent || user?.isVip;

  const resetProgrammation = useMutation<MutationData & { newProgrammation: Programmation }, ApiError, SchoolNivelEnum>({
    mutationFn: (nivel) => {
      return api.programmation.reset(nivel);
    },
    onSuccess: (data) => {
      setProgrammation(data.newProgrammation);
    },
    onError: (data) => {
      toast.info(data.message, { toastId: "reset-programmation" });
    },
  });

  return (
    <Modal show={show} size="xl" onHide={closeModal}>
      <Modal.Header>
        <h4>Programmation</h4>
        <CloseButton variant="white" onClick={closeModal} />
      </Modal.Header>
      <Modal.Body>
        {!user ? (
          <LinearProgress className="m-auto w-100" />
        ) : (
          <div>
            {!canModify && (
              <div className="alert alert-info text-center" role="alert">
                <FontAwesomeIcon icon={faInfoCircle} size="xl" className="me-3" />
                Vous devez être adhérent pour pouvoir modifier la programmation
              </div>
            )}
            <div className="d-flex flex-column mb-4 row-gap-2">
              <div className="d-flex">
                <div className="rounded-5 d-flex justify-content-center" style={{ backgroundColor: "#008000", width: 32, height: 32 }}>
                  <FontAwesomeIcon className="text-white align-self-center" icon={faLessThanEqual} />
                </div>
                <span className="align-self-center ms-2 fw-bold">Taille maximale des données</span>
              </div>
              <div className="d-flex">
                <div className="rounded-5 d-flex justify-content-center" style={{ backgroundColor: "#993366", width: 32, height: 32 }}>
                  <FontAwesomeIcon className="text-white align-self-center" icon={faFont} />
                </div>
                <span className="align-self-center ms-2 fw-bold">Nombre de caractères maximum dans l'énoncé</span>
              </div>
              <div className="d-flex">
                <div className="rounded-5 d-flex justify-content-center" style={{ backgroundColor: "#9E29FF", width: 32, height: 32 }}>
                  <FontAwesomeIcon className="text-white align-self-center" icon={faRoute} />
                </div>
                <span className="align-self-center ms-2 fw-bold">Nombre maximal de données dans un problème à étapes</span>
              </div>
              <div className="d-flex">
                <div className="rounded-5 text-center text-white fs-2" style={{ backgroundColor: "#E06034", width: 32, height: 32, lineHeight: "8px" }}>
                  ,
                </div>
                <span className="align-self-center ms-2 fw-bold">Présence de nombres décimaux</span>
              </div>
            </div>
            <div className="d-flex flex-column row-gap-5">
              {Object.values(SchoolNivelEnum).map((nivel) => (
                <div key={nivel}>
                  <div className="text-uppercase fw-bold text-center bg-lightgrey rounded-2 py-1 mb-2 position-relative">
                    {nivel}{" "}
                    <FormButtonLoading
                      className="btn btn-sm btn-primary p-0 position-absolute me-2 px-2 end-0"
                      label="Réinitialiser"
                      isPending={resetProgrammation.isPending && resetProgrammation.variables === nivel}
                      onClick={() => resetProgrammation.mutate(nivel)}
                      disabled={!canModify}
                    />
                  </div>
                  <div className="row row-cols-5 m-0 p-0">
                    <span className="text-center fw-bold">Période n°1</span>
                    <span className="text-center fw-bold">Période n°2</span>
                    <span className="text-center fw-bold">Période n°3</span>
                    <span className="text-center fw-bold">Période n°4</span>
                    <span className="text-center fw-bold">Période n°5</span>
                    {periods.map((period) => {
                      let filters = user?.programmation?.[nivel]?.[period];
                      if (!filters && !Number.isInteger(period)) {
                        filters = user?.programmation?.[nivel]?.[Math.floor(period) as Period];
                      }
                      const types = Array.from(new Set(filters?.f1 ?? (Object.keys(TextProblemTypeEnum) as (keyof typeof TextProblemTypeEnum)[])));
                      const allowDecimals = filters?.f5 === undefined || filters.f5 === true;
                      return (
                        <div key={period} className="col px-2 mt-1">
                          <div
                            className="d-flex flex-column row-gap-2 h-100 p-2 bg-light position-relative"
                            style={{
                              border: "2px solid black",
                              borderBottomLeftRadius: Number.isInteger(period) ? 0 : 10,
                              borderBottomRightRadius: Number.isInteger(period) ? 0 : 10,
                              borderTopLeftRadius: Number.isInteger(period) ? 10 : 0,
                              borderTopRightRadius: Number.isInteger(period) ? 10 : 0,
                            }}
                          >
                            <div className="d-flex">
                              <div className="overflow-hidden">
                                {types.length === Object.keys(TextProblemTypeEnum).length ? (
                                  <span className="badge p-1 mw-100 text-bg-secondary rounded-2 ms-1 mt-1 overflow-hidden" style={{ textOverflow: "ellipsis" }}>
                                    Tous <FontAwesomeIcon className="ms-1" icon={faCheck} />
                                  </span>
                                ) : (
                                  <>
                                    <span className="badge p-1 mw-100 text-bg-secondary rounded-2 ms-1 mt-1 type-dropdown overflow-hidden" style={{ textOverflow: "ellipsis" }}>
                                      {types.length > 1 && <FontAwesomeIcon className="me-1" icon={faCaretDown} />}
                                      {TextProblemTypeEnum[types[0]]}
                                      {getOperators(TextProblemTypeEnum[types[0]], true)}
                                    </span>
                                    {types.length > 1 && (
                                      <ul className="p-0 text-bg-secondary rounded-2 position-absolute z-1 ms-1" style={{ marginTop: -26 }}>
                                        {types.map((element) => (
                                          <span key={element} className="badge d-block text-start text-black">
                                            {TextProblemTypeEnum[element]}
                                            {getOperators(TextProblemTypeEnum[element], true)}
                                          </span>
                                        ))}
                                      </ul>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="d-flex">
                              <div className="rounded-5 d-flex justify-content-center" style={{ backgroundColor: "#008000", width: 28, height: 28 }}>
                                <FontAwesomeIcon className="text-white align-self-center" size="xs" icon={faLessThanEqual} />
                              </div>
                              <span className="ms-2 align-self-center">{filters?.f2 ?? "-"}</span>
                            </div>
                            <div className="d-flex">
                              <div className="rounded-5 d-flex justify-content-center" style={{ backgroundColor: "#993366", width: 28, height: 28 }}>
                                <FontAwesomeIcon className="text-white align-self-center" size="xs" icon={faFont} />
                              </div>
                              <span className="ms-2 align-self-center">{filters?.f3 ?? "-"}</span>
                            </div>
                            <div className="d-flex">
                              <div className="rounded-5 d-flex justify-content-center" style={{ backgroundColor: "#9E29FF", width: 28, height: 28 }}>
                                <FontAwesomeIcon className="text-white align-self-center" size="xs" icon={faRoute} />
                              </div>
                              <span className="ms-2 align-self-center">{filters?.f4 ?? "-"}</span>
                            </div>
                            <div className="d-flex">
                              <div className="rounded-5 text-center text-white fs-3" style={{ backgroundColor: "#E06034", width: 28, height: 28, lineHeight: "8px" }}>
                                ,
                              </div>
                              <span className="ms-2 align-self-center">{allowDecimals ? "Oui" : "Non"}</span>
                            </div>
                            <button
                              className="btn btn-sm btn-outline-primary position-absolute bottom-0 end-0 m-1"
                              onClick={() => openModal(ModalTypeEnum.EditProgrammation, { nivel: nivel, period: period })}
                              disabled={!canModify}
                            >
                              <FontAwesomeIcon icon={faPen} />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ProgrammationModal;
