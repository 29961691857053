import sanitizeHtml from "sanitize-html";

const br = (text: string) => {
  return text.replace(/\r\n|\r|\n/g, "<br>");
};

const addBoldToQuestion = (text: string) => {
  return text.replace(
    /([A-Z0-9ÀÉ][^.?!]+?\?)|((Calcule|Calculate|Calcola|Calcula|Berechne|Indique|Indicate|Indica|Gib|Donne|Dai|Trouve|Find|Trova|Encuentra|Finde|Cherche|Cerca|Busca)[^.?!]+?\.)/g,
    function (question) {
      return '<span class="fw-bold">' + question + "</span>";
    }
  );
};

const defaultOptions: sanitizeHtml.IOptions = {
  allowedTags: ["br", "span"],
  allowedAttributes: {
    span: ["class"],
  },
};

const sanitize = (dirty: string) => ({
  __html: sanitizeHtml(dirty, defaultOptions),
});

const useUtils = () => {
  return {
    addBoldToQuestion,
    br,
    sanitize,
  };
};

export default useUtils;
