import { Link } from "react-router-dom";
import { useDocumentTitle } from "../hooks";
import Routes from "../routes";

function Privacy() {
  useDocumentTitle("Politique de confidentialité | Appenvie");

  return (
    <div className="my-0 mx-auto" style={{ maxWidth: 960 }}>
      <h1 className="text-center">Politique de confidentialité</h1>
      <p className="mt-3 fst-italic">Version de la politique de confidentialité du 01/09/2024</p>

      {/* Préambule =========================================================== */}

      <h2 className="mt-3">Préambule</h2>

      <p>La présente politique de confidentialité a pour but d'informer les utilisateurs du site&nbsp;:</p>
      <ul>
        <li>
          sur la manière dont sont collectées leurs données personnelles. Sont considérées comme des données personnelles, toute information permettant d'identifier un utilisateur. À ce titre, il peut
          s'agir : de ses noms et prénoms, de son âge, de son adresse postale ou email, de sa localisation ou encore de son adresse IP (liste non-exhaustive)&nbsp;;
        </li>
        <li>sur les droits dont ils disposent concernant ces données&nbsp;;</li>
        <li>sur la personne responsable du traitement des données à caractère personnel collectées et traitées&nbsp;;</li>
        <li>sur les destinataires de ces données personnelles&nbsp;;</li>
        <li>sur la politique du site en matière de cookies.</li>
      </ul>

      <p>
        Cette politique complète les <Link to={Routes.LEGAL_NOTICES}>mentions légales</Link> et les <Link to={Routes.CGU}>Conditions Générales d'Utilisation</Link> consultables par les utilisateurs.
      </p>

      {/* Données collectées ================================================== */}

      <h2 className="mt-3">Données collectées</h2>

      <p className="mt-3">Les données personnelles collectées dans le cadre de notre activité sont les suivantes&nbsp;:</p>
      <ul>
        <li>Pseudo</li>
        <li>Adresse électronique</li>
        <li>Type de compte (enseignant/parent)</li>
        <li>Adresse IP</li>
        <li>Zone de vacances</li>
      </ul>

      <p className="mt-3">L'adresse électronique que vous renseignez lors de votre inscription sert au même titre que votre pseudo à vous authentifier.</p>

      <p className="mt-3">
        L'adresse électronique ne sera jamais affichée en clair sur la plateforme. Elle est aussi le seul et unique moyen d'identifier votre compte pour des demandes spécifiques comme une demande de
        suppression de son compte.
      </p>

      <p className="mt-3">Votre adresse électronique peut être utilisée dans les cas suivants&nbsp;:</p>
      <ul>
        <li>courriel de bienvenue au moment de votre inscription&nbsp;;</li>
        <li>courriel de réinitialisation de mot de passe si vous en avez fait la demande ;</li>
        <li>courriel de confirmation de votre adresse électronique suite à votre inscription ;</li>
        <li>courriel pour vous informer de votre accès complet à une banque collaborative (banque de problèmes, banque de photos ou banque de photo-problèmes).</li>
      </ul>

      <p className="mt-3">Selon vos paramétrages de notifications vous êtes susceptible de recevoir des courriels dans les cas suivants&nbsp;:</p>
      <ul>
        <li>récapitulatif mensuel des contributions et autres interactions sur la banque de problème.</li>
      </ul>

      <p className="mt-3">Le pseudo est visible publiquement sur le site pour relier une contribution à un utilisateur.</p>

      <p className="mt-3">Le type de compte nous permet de vous donner accès au module «&nbsp;L'atelier&nbsp;», au module «&nbsp;En classe&nbsp;» ou au module «&nbsp;A la maison&nbsp;».</p>

      <p className="mt-3">
        L'adresse IP permet d'avoir une information sur le nombre de visites journalières du site. L'adresse IP peut également se retrouver dans des fichiers journaux (fichier de log).
      </p>

      <p className="mt-3">La zone de vacances permet d'adapter le contenu de l'atelier et du rituel de problème selon une programmation de problème basé sur les périodes scolaires.</p>

      <p className="mt-3">Deux cookies sont utilisés dans le seul but de vous permettre de rester connecté sur le site sans avoir besoin de saisir vos identifiants à chaque visite.</p>

      <p className="mt-3">Un cookie est utilisé pour sauvegarder la langue choisie pour l'affichage des problèmes dans la banque. Ce cookie à une durée de vie d'une heure.</p>

      {/* Mode de collecte des données ================================================== */}

      <h2 className="mt-3">Mode de collecte des données</h2>

      <p className="mt-3">Vos renseignements personnels sont collectés par le biais de formulaires, à savoir&nbsp;:</p>
      <ul>
        <li>formulaire d'inscription au site&nbsp;;</li>
        <li>formulaires présents dans les paramètres de votre profil.</li>
      </ul>

      <p className="mt-3">Nous collectons aussi certaines données comme votre adresse IP lorsque vous naviguez simplement sur le site.</p>

      <p className="mt-3">
        Les données sont conservées par le responsable du traitement dans des conditions raisonnables de sécurité, pour une durée infinie jusqu'à ce que vous ou le gestionnaire du site décidiez de les
        supprimer ou les modifier.
      </p>

      <p className="mt-3">Les fichiers journaux ont une durée de vie de 10&nbsp;jours.</p>

      <p className="mt-3">
        L'hébergement des données se fait sur des serveurs de la société O2Switch, basé en France. Voir les <Link to={Routes.LEGAL_NOTICES}>mentions légales</Link>.
      </p>

      <p className="mt-3">Aucune donnée n'est partagé avec un tiers. Les données ne sont utilisées qu'à des fins de fonctionnement de la plateforme ou de communication sur ses fonctionnalités.</p>

      {/* Les droits de l'utilisateur en matière de collecte et de traitement des données */}

      <h2 className="mt-3">Les droits de l'utilisateur en matière de collecte et de traitement des données</h2>

      <p className="mt-3">
        Tout utilisateur concerné par le traitement de ses données personnelles peut se prévaloir des droits suivants, en application du règlement européen 2016/679 et de la Loi Informatique et
        Liberté (Loi 78-17 du 6 janvier 1978) :
      </p>
      <ul>
        <li>droit d'accès, de rectification et droit à l'effacement des données (posés respectivement aux articles 15, 16 et 17 du RGPD)&nbsp;;</li>
        <li>droit à la portabilité des données (article 20 du RGPD)&nbsp;;</li>
        <li>droit à la limitation (article 18 du RGPD) et à l'opposition du traitement des données (article 21 du RGPD)&nbsp;;</li>
        <li>droit de ne pas faire l'objet d'une décision fondée exclusivement sur un procédé automatisé&nbsp;;</li>
        <li>droit de saisir l'autorité de contrôle compétente (article 77 du RGPD).</li>
      </ul>

      <p className="mt-3">
        Pour exercer vos droits, veuillez adresser votre courrier à l'association M@ths'n Co. 60 place du poilu 74400 Chamonix ou via le <Link to={Routes.CONTACT}>formulaire de contact</Link>.
      </p>

      <p className="mt-3">
        Afin que le responsable du traitement des données puisse faire droit à sa demande, l'utilisateur est tenu de lui communiquer certaines informations telles que : son nom, prénom, et adresse
        e-mail utilisé sur la plateforme.
      </p>

      <p className="mt-3">
        A noter, que lorsque l'utilisateur effectue une contribution publique d'un problème, photo ou photo-problème, il ne lui est pas possible de demander une suppression de celle-ci. Il peut, en
        revanche, demander une anonymisation de sa contribution. Ainsi sa contribution ne pourra lui être relié à lui d'une quelconque manière.
      </p>

      <p className="mt-3">
        Il vous est possible, depuis votre profil, de supprimer votre compte. En faisant cette opération, toutes les données vous concernant seront soit supprimées (données personnelles), soit
        anonymisées (contributions).
      </p>

      <p className="mt-3">
        La présente Politique de Confidentialité pourra faire l'objet de modifications conformément à une éventuelle évolution des Services. Ces modifications peuvent avoir lieu en tout temps et sans
        préavis. Aussi les internautes sont-ils invités à consulter cette Politique de Confidentialité régulièrement.
      </p>
    </div>
  );
}

export default Privacy;
